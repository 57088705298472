import { isUndefined } from 'lodash';
import { logDebug } from '../lib/logger';

/**
 * Checks if the payment data has a pending action.
 *
 * @param {Object} paymentData - The payment data object.
 * @param {string} paymentData.pending_action - The pending action status.
 * @returns {boolean} - Returns true if the pending_action is undefined, otherwise false.
 */
const hasPendingAction = (paymentData) => {
    return isUndefined(paymentData.pending_action);
};

/**
 * Handles the pending action for a payment.
 *
 * @param {Object} paymentData - The payment data object.
 * @param {string} paymentData.status - The status of the payment.
 * @param {string} paymentData.status_detail - The detailed status of the payment.
 * @param {Function} setOpen - Function to set the open state.
 * @param {Function} setPendingActionUrl - Function to set the pending action URL.
 * @param {Function} setOrder - Function to update the order.
 * @param {Object} order - The order object.
 * @param {string} order.order_id - The ID of the order.
 * @param {string} order.order_received - The date the order was received.
 */
const pendingActionHandler = (paymentData, setOrder, order) => {
    logDebug('pendingActionHandler', paymentData);
    if (paymentData.status === 'pending') {
        setOrder({
            ...order,
            ...{
                order_id: order.order_id,
                date_received: order.order_received,
                payment_status: paymentData.status,
                payment_status_detail: paymentData.status_detail,
                payment_pending_url: paymentData.pending_action.url,
            },
        });
        // TODO where are we opening the modal?
        // setOpen(true);
        // setPendingActionUrl(paymentData.status_detail.url);
    }
    if (paymentData.status === 'in_process') {
        // TODO in_process is an Mercadopago status handle it
        // setOrder({
        //     ...updated_order,
        //     ...{
        //         order_id: order.order_id,
        //         date_received: order.order_received,
        //         payment_status: paymentData.status,
        //         payment_status_detail: paymentData.status_detail,
        //     },
        // });
    }
};

export { hasPendingAction, pendingActionHandler };
