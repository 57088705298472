import React, { useEffect } from 'react';
import Box from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { isEmpty, isNil } from 'lodash';
import { logDebug } from '../lib/logger';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 900,
    border: 'thin solid #000',
    boxShadow: 24,
    p: 2,
};

const PendingActionModal = ({ open, setOpen, url }) => {
    useEffect(() => {
        if (!isEmpty(url) && !isNil(url)) {
            window.addEventListener('message', (event) => {
                if (event.origin !== new URL(url).origin) {
                    return; // Ignorar mensajes de otros orígenes
                }
                if (event.data?.paymentId) {
                    const returnedPaymentId = event.data.paymentId;
                    logDebug('Returned Payment ID:', returnedPaymentId);
                    // TODO removeEvent
                }
            });
        }
    }, [url]);
    return (
        <Modal
            sx={style}
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box>
                <Paper>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Confirma el codigo que envio tu banco
                    </Typography>
                    <iframe
                        title="cybersource3Ds"
                        data-testid="cybersource3Ds-iframe"
                        style={{
                            display: 'block',
                            width: '900px',
                            height: '900px',
                        }}
                        src={url}
                        frame-src={url}
                    ></iframe>
                </Paper>
            </Box>
        </Modal>
    );
};

export default PendingActionModal;
